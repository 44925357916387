import Vue from 'vue'
import _ from 'lodash'
import router from '@/router'
import vuetify from '@/plugins/vuetify'
import Http from '@/plugins/http'
import store from '@/store/store'

function initSettings () {
  return window.sessionStorage.themeController
    ? Object.assign(defaultSettings(), JSON.parse(window.sessionStorage.themeController))
    : defaultSettings()
}

function defaultSettings () {
  return {
    isFramed: false,
    isDark: false,
    backgroundImageUrl: null,
    bannerColor: null,
    leftDrawerColor: null,
    primary: '#00ACC6',
    secondary: '#F18F02',
    accent: '#6200EE',
    borderRadius: 10
  }
}

export default new Vue({
  vuetify,
  store,
  Http,
  router,
  data: initSettings,
  created () {
    this.styleElement = document.createElement('style')
    this.styleElement.type = 'text/css'
    this.styleElement.appendChild(document.createTextNode(''))
    this.styleContent = this.styleElement.childNodes[0]
    document.head.appendChild(this.styleElement)
    this.$nextTick(() => this.setVuetifyTheme())
  },
  destroyed () {
    document.head.removeChild(this.styleElement)
  },
  computed: {
    debouncedQuery () {
      return _.debounce(async () => {
        if (this.$store.getters.currentUser.id) {
          const d = this.$data
          const settings = {
            isFramed: d.isFramed,
            isDark: d.isDark,
            backgroundImageUrl: d.backgroundImageUrl,
            primary: d.primary,
            secondary: d.secondary,
            accent: d.accent,
            borderRadius: d.borderRadius
          }
          this.$http().put(`/core/v6/users/${this.$store.getters.currentUser.id}/ui-settings`, settings)
            .catch(() => { })
        }
      }, 3000)
    },
    radius () {
      return this.borderRadius || 10
    },
    cssProps () {
      return `
      :root {
        --primary-lightness: ${this.getLightness(this.primary)}%;
        --secondary-lightness: ${this.getLightness(this.secondary)}%;
        --accent-lightness: ${this.getLightness(this.accent)}%;
        --primary-color: ${this.primary};
        --primary-color-10: ${this.primary}1A;
        --secondary-color: ${this.secondary};
        --secondary-color-10: ${this.secondary}1A;
        --accent-color: ${this.accent};
        --bg-transparency: ${this.getBackgroundTransparency()};
        --bg-plain: ${this.getBackgroundPlain()};
        --border-radius: ${this.radius}px;
        --border-radius-left:  ${this.radius}px 0px 0px ${this.radius}px;
        --border-radius-right: 0px ${this.radius}px ${this.radius}px 0px;
        --border-radius-top:  ${this.radius}px ${this.radius}px 0px 0px;
      }
    `
    },
    customizableColors: function () {
      return Object.assign({}, {
        primary: this.$vuetify.theme.themes.dark.primary,
        secondary: this.$vuetify.theme.themes.dark.secondary,
        accent: this.$vuetify.theme.themes.dark.accent
      })
    },
    borderRadiusSettings () {
      return {
        minBorderRadius: 2,
        maxBorderRadius: 10
      }
    }
  },
  methods: {
    getLightness (hexColor) {
      const threshold = 0.61 // 0.54
      const r = parseInt(hexColor.substring(1, 3), 16) * 0.2126
      const g = parseInt(hexColor.substring(3, 5), 16) * 0.7152
      const b = parseInt(hexColor.substring(5, 7), 16) * 0.0722
      return (((r + g + b) / 255) - threshold) * -10000000 <= 0 ? 0 : 100
    },
    getBackgroundTransparency () {
      if (!this.backgroundImageUrl) {
        return this.isDark ? '#222222' : 'rgba(255, 255, 255)'
      }
      return this.isDark ? 'rgba(0, 0, 0, 0.8 )' : 'rgba(255, 255, 255, 0.8)'
    },
    getBackgroundPlain () {
      return this.isDark ? '#303030' : '#f2f2f2'
    },
    setVuetifyTheme () {
      this.$vuetify.theme.dark = this.isDark
      let targetColor = this.primary

      this.$vuetify.theme.themes.dark.primary = this.primary
      this.$vuetify.theme.themes.light.primary = this.primary
      this.$vuetify.theme.themes.dark.secondary = this.secondary
      this.$vuetify.theme.themes.light.secondary = this.secondary
      this.$vuetify.theme.themes.dark.accent = this.accent
      this.$vuetify.theme.themes.light.accent = this.accent

      targetColor = this.backgroundImageUrl ? this.getBackgroundTransparency() : this.getBackgroundPlain()
      this.bannerColor = targetColor
      this.leftDrawerColor = this.isDark ? '#222222' : '#FFFFFF'

      this.styleContent.textContent = this.cssProps
      this.storeInBrowser()
      this.saveThemeSettings()
    },
    resetUiSettings () {
      this.displayResetModal = false
      Object.assign(this.$data, defaultSettings())
      this.setVuetifyTheme()
    },
    storeInBrowser () {
      window.sessionStorage.themeController = JSON.stringify(this.$data)
    },
    saveThemeSettings () {
      this.debouncedQuery()
    },
    async loadDefaultTheme () {
      this.resetUiSettings(false)
    }
  },
  watch: {
    isFramed: function () {
      this.setVuetifyTheme()
    },
    borderRadius: function () {
      this.setVuetifyTheme()
    },
    isDark: function () {
      this.setVuetifyTheme()
    },
    backgroundImageUrl: function () {
      this.setVuetifyTheme()
    },
    primary: function () {
      this.setVuetifyTheme()
    },
    secondary: function () {
      this.setVuetifyTheme()
    },
    accent: function () {
      this.setVuetifyTheme()
    },
    '$store.getters.currentUser.id': {
      handler (id) {
        if (id) {
          this.$http().get(`/core/v6/users/${id}/ui-settings`).then(r => {
            if (r.data) {
              const s = r.data
              Object.assign(this.$data, s)
            }
          })
            .catch(() => { })
        }
      },
      deep: false,
      immediate: false
    }
  }
})
