<template>
  <v-card class="accounts-collection-segments">
    <v-card-title>Collection Segments</v-card-title>
    <graph
      width="400px"
      height="200px"
      :options="chartOptions"
      :series="series"
    />
  </v-card>
</template>

<script>
import Graph from '@/components/graph.vue'

export default {
  components: {
    Graph
  },
  data () {
    return {
      chartOptions: {
        chart: {
          type: 'donut'
        },
        labels: [
          'Clients à risque',
          'Clients occasionnels',
          'Default',
          'Fonds de commerce',
          'Grands comptes',
          'Interco'
        ],
        stroke: {
          width: 0
        },
        xaxis: {
          type: 'string'
        }
      },
      series: [
        6,
        383,
        51,
        114,
        144,
        29
      ]
    }
  }
}
</script>
