<template>
  <component
    :is="component"
    class="graph"
    :width="width"
    :height="height"
    :options="chartOptions"
    :series="series"
    :type="type"
  />
</template>

<script>
import themeController from '@/themeController'
export default {
  inject: {
    viewport: {
      default () {
        return { isMobile: false }
      }
    }
  },
  activated () {
    this.$nextTick(() => { this.component = 'Apexchart' })
  },
  beforeDestroy () {
    this.component = null
  },
  components: {
    Apexchart: () => import('vue-apexcharts')
  },
  computed: {
    chartOptions () {
      const options = this.options || {}
      const target = {
        chart: {
          background: 'none',
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          }
        },
        // colors: ['#5DBCD2', '#4CAF50', '#FFEB3B', '#F44336', '#9C27B0', '#3F51B5', '#CDDC39', '#FF5722', '#E91E63'],
        dataLabels: {
          dropShadow: {
            enabled: false
          },
          style: {
            fontWeight: 'normal',
            colors: [this.$vuetify.theme.dark ? 'white' : 'black']
          }
        },
        fill: {
          gradient: {
            inverseColors: false,
            shade: this.$vuetify.theme.dark ? 'dark' : 'light',
            type: 'vertical',
            opacityFrom: 0.85,
            opacityTo: 0.55,
            stops: [0, 100, 100, 100]
          }
        },
        grid: {
          show: false
        },
        markers: {
          size: 0
        },
        plotOptions: {
          bar: {
            distributed: !(options.chart && options.chart.stacked),
            borderRadius: this.viewport.isMobile || themeController.borderRadius === 2 ? 0 : themeController.borderRadius
          }
        },
        stroke: {
          curve: 'smooth',
          width: 2

        },
        tooltip: {
          shared: false,
          followCursor: false
        },
        xaxis: {
          datetimeFormatter: {
            year: 'yyyy',
            month: "MMM 'yy"
          }
        },
        yaxis: {
          forceNiceScale: true
        },
        theme: {
          mode: this.$vuetify.theme.dark ? 'dark' : 'light',
          palette: 'palette1'
        }
      }

      return this.lodash.defaultsDeep(options, target)
    }
  },
  data () {
    return {
      component: null
    }
  },
  deactivated () {
    this.component = null
  },
  mounted () {
    this.$nextTick(() => { if (this.$el.isConnected) { this.component = 'Apexchart' } })
  },
  props: {
    height: {
      type: [String, Number],
      default: () => 'auto'
    },
    options: Object,
    series: Array,
    width: String,
    type: String
  }
}
</script>

<style lang="stylus" scoped>
/deep/.apexcharts-canvas.apexcharts-theme-dark
  background none
</style>
